import { Fade } from 'react-slideshow-image';

import 'react-slideshow-image/dist/styles.css';

import usePhotos from "./usePhotos";

function App() {
  const { items } = usePhotos();

  return (
    <div className="slide-container">
      <Fade arrows={false} autoplay={true} infinite={true} pauseOnHover={false} canSwipe={false}>
        {items.map(({ url, id }) => (
          <img style={{width: '100vw', height: '100vh', objectFit: 'contain', background: 'black'}} src={`${url}&w=1600`} alt="" key={`image_${id}`}/>
        ))}
      </Fade>
    </div>
  );
}

export default App;
