import firebase from 'firebase/app';
// import 'firebase/auth';
// import 'firebase/database';
import 'firebase/firestore';
// import 'firebase/functions';

const firebaseConfig = {
  apiKey: "AIzaSyCMFD2NONFFxu3RiUN74cIt-IsM5E5EvQw",
  authDomain: "julie-et-quentin-1d8e1.firebaseapp.com",
  projectId: "julie-et-quentin-1d8e1",
  storageBucket: "julie-et-quentin-1d8e1.appspot.com",
  messagingSenderId: "631060431748",
  appId: "1:631060431748:web:073b44ed51c883ccf4d3d1"
};

if (firebase.apps.length === 0) {
  firebase.initializeApp(firebaseConfig);
  firebase.firestore();
}

export default firebase;
