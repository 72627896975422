import { useCollection } from 'react-firebase-hooks/firestore';

import firebase  from './firebase';

const getPhotoUrl = (filename, options) => {
  const queryParams = {
    ...options,
    signature: process.env.REACT_APP_SIGNATURE,
  }

  const queryString = Object.keys(queryParams).map(param => `${param}=${queryParams[param]}`).join('&');

  return `${process.env.REACT_APP_BASE_URL}/images/${filename}?${queryString}`;
}

const usePhotos = () => {
  const [ snapshot, loading, error ] = useCollection(
    firebase.firestore().collection(`/${process.env.REACT_APP_PHOTO_COLLECTION}`).orderBy('created_at', 'asc')
  );

  return {
    items: snapshot ? snapshot.docs.map(doc => {
      const data = doc.data();

      return {
        id: doc.id,
        ...data,
        url: getPhotoUrl(data.filename),
      };
    }) : [],
    loading,
    error,
  };
};

export default usePhotos;
